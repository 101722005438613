const AXES_FONT_COLOR = "#A3A3A3";
const LEGEND_FONT_COLOR = "#343434";
const DETAIL_CHART_PRIMARY_COLOR = "#1F78B4";
const DETAIL_CHART_SECONDARY_COLOR = "#E1E6EF";
const FONT_FAMILY =
  "'Montserrat', 'Avenir', 'Helvetica', 'Arial', 'sans-serif'";
const HISTORICAL_STATUS_RED_LIGHT = "rgba(239, 67, 82, 0.1)";
const HISTORICAL_STATUS_RED_DARK = "rgba(229, 46, 60, 1)";
const HISTORICAL_STATUS_YELLOW_LIGHT = "rgba(255, 201, 7, 0.1)";
const HISTORICAL_STATUS_YELLOW_DARK = "rgba(255, 201, 7, 1)";
const HISTORICAL_STATUS_GREEN_LIGHT = "rgba(4, 114, 13, 0.1)";
const HISTORICAL_STATUS_GREEN_DARK = "rgba(4, 114, 13, 1)";
const HISTORICAL_STATUS_GREY_LIGHT = "rgba(165, 165, 165, 0.1)";
const HISTORICAL_STATUS_GREY_DARK = "rgba(165, 165, 165, 1)";
const HISTORICAL_STATUS_BLUE_LIGHT = "rgba(0, 51, 102, 0.1)";
const HISTORICAL_STATUS_BLUE_DARK = "rgba(0, 51, 102, 1)";

const tooltips = {
  enabled: true,
  backgroundColor: "#fff",
  titleFontColor: "#000",
  bodyFontColor: "#000",
  titleAlign: "center",
  cornerRadius: 4,
  intersect: true,
  borderColor: "#A3A3A3",
  borderWidth: 1.5,
  displayColors: false,
};

const point = {
  radius: 0,
  hoverRadius: 4,
  hoverBorderWidth: 2,
  hitRadius: 5,
};
const legend = {
  display: true,
  position: "top",
  align: "center",
  labels: {
    usePointStyle: true,
    fontColor: LEGEND_FONT_COLOR,
    fontFamily: FONT_FAMILY,
    fontSize: 12,
    padding: 10,
  },
};

const detailChartXTicks = {
  fontColor: AXES_FONT_COLOR,
  callback: (value, index, values) => {
    const dayMonthYear = value.split(" ");
    const day = dayMonthYear[0];
    const firstIndex = 0;
    const lastIndex = values.length - 1;
    const isFirstOrLast = [firstIndex, lastIndex].includes(index);
    const show1stOfMonth = values.length >= 60;
    const beginDateTooClose = day > 20;
    const lastDateTooClose = day < 10;

    // only show 1st of month tick if more than 2 months of data is showing
    if (show1stOfMonth) {
      // Don't show last or first date if it's too close to 1st day of month
      // JS converts string to number automatically when comparing string to number
      if (index == firstIndex && beginDateTooClose) {
        return "";
      }
      if (index == lastIndex && lastDateTooClose) {
        return "";
      }
      // Only show 1st of month unless it's beginning or last of labels
      if (day != 1 && !isFirstOrLast) {
        return "";
      }
    }

    const month = dayMonthYear[1];
    const dayMonth = [day, month].join(" ");
    return dayMonth;
  },
  fontFamily: FONT_FAMILY,
};

const historicalStatusChartConfig = {
  type: "line",
  data: {
    labels: [],
    datasets: [
      {
        label: "Red",
        backgroundColor: HISTORICAL_STATUS_RED_LIGHT,
        borderColor: HISTORICAL_STATUS_RED_DARK,
        borderWidth: 1,
        data: [],
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: true,
    elements: {
      point: point,
    },
    tooltips: tooltips,
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: detailChartXTicks,
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            min: 0,
            fontColor: AXES_FONT_COLOR,
            maxTicksLimit: 4,
            fontFamily: FONT_FAMILY,
          },
        },
      ],
    },
  },
};

const dailyWaterflowChartConfig = {
  type: "line",
  data: {
    labels: [],
    datasets: [
      {
        type: "line",
        label: "Water Flow",
        borderColor: DETAIL_CHART_PRIMARY_COLOR,
        pointBackgroundColor: DETAIL_CHART_PRIMARY_COLOR,
        data: [0],
        lineTension: 0,
        fill: false,
      },
      {
        type: "line",
        label: "Rolling Average",
        borderColor: DETAIL_CHART_SECONDARY_COLOR,
        data: [0],
        fill: false,
        lineTension: 0,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: point,
    },
    tooltips: tooltips,
    legend: legend,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: true,
          ticks: detailChartXTicks,
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            fontColor: AXES_FONT_COLOR,
            fontFamily: FONT_FAMILY,
          },
        },
      ],
    },
  },
};

const dailyActiveHoursChartConfig = {
  type: "bar",
  data: {
    labels: [],
    datasets: [
      {
        type: "bar",
        label: "Active Hours",
        backgroundColor: DETAIL_CHART_PRIMARY_COLOR,
        data: [],
        order: 1,
      },
      {
        type: "line",
        label: "Rolling Average",
        borderColor: DETAIL_CHART_SECONDARY_COLOR,
        data: [],
        fill: false,
        lineTension: 0,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: point,
    },
    tooltips: tooltips,
    legend: legend,
    scales: {
      xAxes: [
        {
          ticks: detailChartXTicks,
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 24,
            fontColor: AXES_FONT_COLOR,
            fontFamily: FONT_FAMILY,
          },
        },
      ],
    },
  },
};

export {
  historicalStatusChartConfig,
  dailyWaterflowChartConfig,
  dailyActiveHoursChartConfig,
  HISTORICAL_STATUS_RED_DARK,
  HISTORICAL_STATUS_RED_LIGHT,
  HISTORICAL_STATUS_YELLOW_DARK,
  HISTORICAL_STATUS_YELLOW_LIGHT,
  HISTORICAL_STATUS_GREEN_DARK,
  HISTORICAL_STATUS_GREEN_LIGHT,
  HISTORICAL_STATUS_GREY_DARK,
  HISTORICAL_STATUS_GREY_LIGHT,
  HISTORICAL_STATUS_BLUE_DARK,
  HISTORICAL_STATUS_BLUE_LIGHT,
};
