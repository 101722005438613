<template>
  <div :class="['select', modifier ? `select--${modifier}` : '']">
    <button
      class="select select--selected-item"
      type="button"
      ref="menuRef"
      @click.stop="onMenuOpen"
      v-click-outside="onMenuClose"
      :value="selected.value"
    >
      {{ selected.label }}
    </button>
    <Transition>
      <div
        v-show="showMenu"
        class="select select--container"
        ref="optionsList"
        :style="menuPosition"
      >
        <ul class="select select--list">
          <template v-for="(item, index) in Object.values(options)">
            <li
              :class="['select', 'select--list__option']"
              v-if="!item.hidden"
              :key="index"
            >
              <button
                class="button"
                type="button"
                :value="item.value"
                @click.stop="onMenuItemChange(item)"
              >
                <div class="list-item">{{ item.label }}</div>
              </button>
            </li>
          </template>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script>
import { getTopLeftPosition } from "./helpers";
import vClickOutside from "v-click-outside";

export default {
  data() {
    return {
      showMenu: false,
      menuPosition: { background: "red" },
    };
  },
  props: {
    selected: {
      type: Object,
      required: true,
    },
    modifier: {
      type: String,
    },
    options: {
      type: Object,
    },
  },
  methods: {
    onMenuItemChange(item) {
      this.showMenu = false;
      this.$emit("menuItemChanged", item);
    },
    onMenuClose() {
      if (this.$data.showMenu) {
        this.showMenu = false;
      }
    },
    onMenuOpen(event) {
      this.showMenu = !this.showMenu;
      const spacing = 12;
      this.$nextTick(() => {
        if (this.showMenu) {
          this.menuPosition = {};
          const button = event.currentTarget;
          const pos = getTopLeftPosition(event.currentTarget);

          if (pos.x === "left" && pos.y === "top") {
            this.menuPosition = {
              left: 0,
              top: `${button.clientHeight + spacing}px`,
            };
          }
          if (pos.x === "right" && pos.y === "top") {
            this.menuPosition = {
              right: 0,
              top: `${button.clientHeight + spacing}px`,
            };
          }
          if (pos.x === "right" && pos.y === "bottom") {
            this.menuPosition = {
              right: 0,
              bottom: `${button.clientHeight + spacing}px`,
            };
          }
          if (pos.x === "left" && pos.y === "bottom") {
            this.menuPosition = {
              left: 0,
              bottom: `${button.clientHeight + spacing}px`,
            };
          }
        }
      });
    },
  },
  directives: { clickOutside: vClickOutside.directive },
};
</script>
